/* Note: use only to override very specific theme styles. 
All other css rules should be written in their own respective sass documents. */

/*
.page-wrapper
  .header-navigation-container
  .main-content
  .footer
*/

/* CUSTOM CONTENT SECTIONS */
.col-3-layout {
display: flex;
justify-content: center;
flex-wrap: wrap;
gap: 40px;

  .header {
    flex: 1 1 300px;
    text-wrap: pretty;
  }

  .containers {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 2vw;
    width: 100%;
    max-width: 800px;

    .facebook-video-vh {
      width: auto;
      height: clamp(180px, 40vw, 400px);
      aspect-ratio: 0.58;
      box-shadow: 1px 1px 10px 9px #b07e1130, 3px 3px 10px 3px #b07e1144;
      border: 2px solid #B07E11;
      position: relative;
      border-radius: 15px;
      overflow: hidden;
    }
  }
}

.fluid-typography-test {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 15px;
  border: 3px solid white;
  border-radius: 12px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  overflow-y: scroll;
  max-height: 500px;
  &::-webkit-scrollbar {
    display: none;
  }

  p {
    color: #F6F6F6;
  }
  
  .tag {
    display: inline-flex;
    padding: 0 4px;
    line-height: normal;
    border-radius: 4px;
    width: fit-content;
  }
  
  .typography-elements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    .typography-element {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      width: 100%;
      /* border: 1px dotted pink; */

      .typography-element__details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        min-width: 300px;

        .props {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;

          .prop {
            width: fit-content;
            border-radius: 4px;
            padding: 0 6px;
  
            border: 1px solid white;
            color: white !important;
            font-weight: 400;
          }
        }
      }

      .typography-element__display {
        margin: auto 0;
        width: fit-content;
        height: 100%;
        border-radius: 4px;
        padding: 0 4px;
        color: white !important;
        font-weight: 400;
      }
    }
  }
}

.post-tag {    
  display: inline-block;
  height: 24px;
  line-height: 24px;
  position: relative;
  /* margin: 0 16px 8px 0; */
  margin: 0 !important;
  padding: 0 10px 0 12px;
  background: #777;    
  border-bottom-right-radius: 3px;    
  border-top-right-radius: 3px;    
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  color: #fff;
  font-size: 12px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(0,0,0,0.2);
  font-weight: bold;
  left: 10px;
  min-width: 50px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -12px;
    width: 0;
    height: 0;
    border-color: transparent #777 transparent transparent;
    border-style: solid;
    border-width: 12px 12px 12px 0;        
  }

  &::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    box-shadow: -1px -1px 2px rgba(0,0,0,0.4);
  }
}